import React from "react";
import useWindowSize from "../helpers/UseWindowSize";
import HomeIntroImage from "../components/home/home-intro-image";
import IntroText from "../components/intro-text";
import FullscreenImage from "../components/fullscreen-image";
import Comfort from "../components/home/comfort";
import Arrival from "../components/home/arrival";
import Kitchen from "../components/home/kitchen";
import HomeSlider from "../components/home/home-slider";
import Restorative from "../components/home/restorative";
import Pampering from "../components/home/pampering";
import PageTransitionSection from "../components/page-transition-section";
import heroImage from "../images/residence-hero-new.jpg";
import heroImageM from "../images/residence-hero-m.jpg";
import PHMap from "../components/home/phMap";
import FooterSeo from "../components/footer-seo";

const introText = {
  title: "NEW CONDOMINIUMS IN NEWPORT BEACH",
  text: "Reimagine Newport Beach living. Parkhouse\u00A0Residences at Uptown Newport offer a limited collection of beautifully finished half-floor condominium residences and two-story penthouses. Each home has been thoughtfully designed by internationally renowned, Costa Mesa-based Blackband Design to emphasize casual sophistication and connection to its Southern California surroundings.",
  text2:
    "Tucked away in a private enclave with ease of access to the best of Newport Beach, this new home community is the culmination of nearly a decade of design and planning in order to create a thoughtful and timeless experience. Discover Parkhouse Residences at Uptown Newport: the ultimate expression of sophisticated coastal living",
};

const IndexPage = () => {
  const size = useWindowSize();
  return (
    <>
      <HomeIntroImage imageSrc={size.width < 560 ? heroImageM : heroImage} />
      <IntroText
        title={introText.title}
        text={introText.text}
        text2={introText.text2}
        columnCount={1}
      />
      <Arrival />
      <HomeSlider
        img1={"residence-3.jpg"}
        label1={"Great Room Plan 1"}
        img2={"residence-3b.jpg"}
        label2={"Great Room Plan 2"}
      />
      <Comfort />
      <FullscreenImage
        label={"Terrace"}
        imageSrc={"residence-5.png"}
        withPadding={size.width < 1025 ? "87.5%" : "80%"}
        labelWidth={size.width < 1025 ? "87.5%" : "80%"}
      />
      <Kitchen />
      <HomeSlider
        img1={"residence-9.png"}
        label1={"CONTEMPORARY KITCHEN"}
        img2={"residence-9b.jpg"}
        label2={"CLASSIC KITCHEN"}
      />
      <Restorative />
      <FullscreenImage
        label={"Primary bedroom"}
        imageSrc={"residence-11.png"}
        withPadding={"80%"}
        labelWidth={"80%"}
      />
      <Pampering />
      <HomeSlider
        img1={"residence-15.png"}
        label1={"CONTEMPORARY PRIMARY BATHROOM"}
        img2={"residence-15b.png"}
        label2={"CLASSIC PRIMARY BATHROOM"}
      />
      <PHMap />
      <PageTransitionSection text={"CONTINUE TO"} linkTo={"penthouses"} />
      <FooterSeo />
    </>
  );
};

export default IndexPage;
