import * as React from "react";
import { FC, useRef } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import {
  HomeSliderItem,
  HomeSliderLabel,
  HomeSliderWrapper,
  LeftArrow,
  RightArrow,
} from "./index.styled";
import Slider from "react-slick";
import { IHomeSliderProps } from "../../../interfaces/home-slider-props";

import leftArrow from "../../../images/icon-arrow-left.svg";
import rightArrow from "../../../images/icon-arrow-right.svg";
import "slick-carousel/slick/slick.css";

function NextArrow(props: { onClick: any }) {
  const { onClick } = props;
  return (
    <RightArrow onClick={onClick} className="slider-arrow">
      <img src={rightArrow} alt="Arrow Right" />
    </RightArrow>
  );
}

function PrevArrow(props: { onClick: any }) {
  const { onClick } = props;
  return (
    <LeftArrow onClick={onClick} className="slider-arrow">
      <img src={leftArrow} alt="Arrow Left" />
    </LeftArrow>
  );
}

const HomeSlider: FC<IHomeSliderProps> = ({ ...props }) => {
  const sliderRef: any | null = useRef(null);
  const homeSliderLabelRef = useRef(null);
  const homeSliderWrapperRef = useRef(null);

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    nextArrow: <NextArrow onClick={props.onClick} />,
    prevArrow: <PrevArrow onClick={props.onClick} />,
  };

  const data = useStaticQuery(graphql`
    query {
      allImageSharp {
        edges {
          node {
            fluid(maxWidth: 2560) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  `);

  return (
    <HomeSliderWrapper ref={homeSliderWrapperRef} className={props.className}>
      <Slider {...settings} ref={sliderRef}>
        <HomeSliderItem>
          <Img
            fluid={
              data.allImageSharp.edges.find((element: any) => {
                return element.node.fluid.src.split("/").pop() === props.img1;
              }).node.fluid
            }
            alt={"Newport beach apartments for sale"}
            loading={"eager"}
          />
          <HomeSliderLabel className={"image-label"}>
            {props.label1}
          </HomeSliderLabel>
        </HomeSliderItem>
        <HomeSliderItem>
          {/*<img src={props.img2} alt={"residence 9"} />*/}
          <Img
            fluid={
              data.allImageSharp.edges.find((element: any) => {
                return element.node.fluid.src.split("/").pop() === props.img2;
              }).node.fluid
            }
            alt={"New condos in Newport Beach"}
            loading={"eager"}
          />
          <HomeSliderLabel className={"image-label"} ref={homeSliderLabelRef}>
            {props.label2}
          </HomeSliderLabel>
        </HomeSliderItem>
      </Slider>
    </HomeSliderWrapper>
  );
};

export default HomeSlider;
