import React, { FC, useEffect, useRef } from "react";
import residence6 from "../../../images/residence-6.png";
import residence7 from "../../../images/residence-7.png";
import residence8 from "../../../images/residence-8.png";
import {
  KitchenContainer,
  KitchenImage,
  KitchenInfo,
  KitchenMainImage,
  KitchenWrapper,
} from "./index.styled";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

const Kitchen: FC = () => {
  const kitchenWrapperRef = useRef<HTMLDivElement>(null);
  const kitchenImage1Ref = useRef<HTMLDivElement>(null);
  const kitchenImage2Ref = useRef<HTMLDivElement>(null);
  const kitchenImage3Ref = useRef<HTMLDivElement>(null);
  const kitchenHeaderRef = useRef<HTMLHeadingElement>(null);
  const kitchenTextRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: kitchenWrapperRef?.current || "",
      toggleActions: "play none none reverse",
      start: "top+=30% bottom",
      animation: gsap
        .timeline()
        .fromTo(
          kitchenImage1Ref?.current || null,
          0.5,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          ".1"
        )
        .fromTo(
          kitchenImage2Ref?.current || null,
          0.5,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          ".2"
        )
        .fromTo(
          kitchenHeaderRef?.current || null,
          0.5,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          ".2"
        )
        .fromTo(
          kitchenTextRef?.current || null,
          0.5,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          ".2"
        )
        .fromTo(
          kitchenImage3Ref?.current || null,
          0.5,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          ".2"
        ),
    });
  }, []);

  const data = useStaticQuery(graphql`
    query {
      residence6: file(relativePath: { eq: "residence-6.png" }) {
        childImageSharp {
          fluid(maxWidth: 2560) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      residence7: file(relativePath: { eq: "residence-7.png" }) {
        childImageSharp {
          fluid(maxWidth: 2560) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      residence8: file(relativePath: { eq: "residence-8.png" }) {
        childImageSharp {
          fluid(maxWidth: 2560) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  return (
    <KitchenWrapper ref={kitchenWrapperRef}>
      <KitchenInfo>
        <KitchenImage ref={kitchenImage1Ref}>
          <Img
            fluid={data.residence6.childImageSharp.fluid}
            alt={"Condominiums Newport Beach"}
          />
        </KitchenImage>
        <KitchenImage ref={kitchenImage2Ref}>
          <Img
            fluid={data.residence7.childImageSharp.fluid}
            alt={"New condos in Newport Beach"}
            loading={"eager"}
          />
        </KitchenImage>
        <KitchenContainer>
          <h2 className={"body-title"} ref={kitchenHeaderRef}>
            DESIGNED FOR EFFORTLESS LIVING
          </h2>
          <p className={"body-copy"} ref={kitchenTextRef}>
            The centrally located kitchen is an art piece unto itself. Designed
            as a dazzling complement to the great room, the grand kitchens are
            intended for the casual cook, the serious entertainer, and everyone
            in between. Finished in custom millwork and countertops, and
            outfitted in the latest appliances, the kitchens’ every detail was
            meticulously hand selected and positioned to optimize functionality
            and space.
          </p>
        </KitchenContainer>
        <KitchenMainImage ref={kitchenImage3Ref}>
          <Img
            fluid={data.residence8.childImageSharp.fluid}
            alt={"Newport beach apartments for sale"}
            loading={"eager"}
          />
        </KitchenMainImage>
      </KitchenInfo>
    </KitchenWrapper>
  );
};

export default Kitchen;
