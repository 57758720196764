import styled from "styled-components";
import { mediaMax } from "../../../helpers/MediaQueries";

export const PHMapWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 140px 10% 20px;
  ${mediaMax.tabletLandscape`
    margin: 50px 6.25% 10px;
    flex-direction: column;
    align-items: center;
  `}
`;
export const PHMapLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 26%;
  h2 {
    margin-bottom: 20px;
  }
  p {
    margin-bottom: 36px;
  }
  ${mediaMax.tabletLandscape`
    width: 100%;
  `}
  ${mediaMax.phoneXL`
    width: 100%;
  `}
`;
export const PHMapRight = styled.div`
  display: flex;
  align-items: baseline;
  width: 64%;
  ${mediaMax.tabletLandscape`
    width: 100%;
    margin: 50px 0 48px 0;
  `}
  ${mediaMax.phoneXL`
    margin: 30px 0 0 0;
  `}
  .gatsby-image-wrapper {
    width: 100%;
  }
`;
