import styled from "styled-components";
import { mediaMax } from "../../../helpers/MediaQueries";

export const ComfortWrapper = styled.div`
  display: flex;
  margin: 0 10% 72px;
  ${mediaMax.tabletLandscape`
    margin: 0 6.25% 48px;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  `}
  ${mediaMax.phoneXL`
    flex-direction: column;
  `};
`;
export const ComfortLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 37%;
  ${mediaMax.tabletLandscape`
    width: 74.4%;
    padding-top: 48px;
    margin-left: 16.6%;
  `}
  ${mediaMax.phoneXL`
    width: 100%;
    padding-top: 0;
    margin-left: 0;
  `};
`;
export const ComfortRight = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  width: 48%;
  ${mediaMax.tabletLandscape`
    width: 57%;
    min-width: 186px;
    margin: 48px auto 0;
  `}
`;
