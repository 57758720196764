import styled from "styled-components";
import { mediaMax } from "../../../helpers/MediaQueries";

export const PamperingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 10% 72px;
  ${mediaMax.tabletLandscape`
    margin: 0 6.25% 48px;
  `}
`;
export const PamperingInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
`;
export const PamperingImage = styled.div`
  width: 23%;
  ${mediaMax.tabletLandscape`
    width: 46.42%;
    order: 1;
  `}
  img {
    width: 100%;
  }
`;
export const PamperingContainer = styled.div`
  width: 38%;
  margin-right: 4%;
  ${mediaMax.tabletLandscape`
    margin: 48px auto 0;
    width: 74.4%;
    order: 2;
  `}
  ${mediaMax.phoneXL`
    margin: 0 auto 48px;
    width: 100%;
    order: 1;
  `}
`;
export const PamperingMainImage = styled.div`
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin: 48px auto 0;
  ${mediaMax.tabletLandscape`
    width: 74.4%;
    order: 3;
  `}
  ${mediaMax.phoneXL`
    width: 54%;
    order: 4;
  `}
  .gatsby-image-wrapper {
    width: 100%;
  }
`;
