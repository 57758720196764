import React, { FC, useEffect, useRef, useContext } from "react";
import Lottie from "react-lottie";
import animation from "../../../../static/intro-logo/logo-animated.json";
import FirstLoadContext from "../../../context";
import gsap from "gsap";
import { TweenLite } from "gsap";

import introArrow from "../../../images/icon-arrow.svg";
import {
  HomeIntroImageArrow,
  HomeIntroImageItem,
  HomeIntroImageWrapper,
  LogoWrapper,
} from "./index.styled";
import { IIntroImageProps } from "../../../interfaces/intro-image-props";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const HomeIntroImage: FC<IIntroImageProps> = ({ imageSrc }) => {
  const { firstLoad } = useContext(FirstLoadContext);
  const tl = gsap.timeline();

  const homeIntroImageWrapperRef = useRef<HTMLDivElement>(null);
  const logoWrapperRef = useRef<HTMLDivElement>(null);
  const homeIntroImageRef = useRef<HTMLImageElement>(null);
  const homeIntroArrowDwnRef = useRef<HTMLImageElement>(null);
  useEffect(() => {
    const headerWrapper = document.getElementById("headerWrapper");
    const layoutWrapper = document.getElementById("layoutWrapper");
    logoWrapperRef?.current?.classList.remove("loading");

    if (firstLoad && location.pathname === "/") {
      window.scroll(0, 0);
      tl.fromTo(
        homeIntroImageRef?.current || null,
        11,
        { objectPosition: "0 0", scale: 2, marginTop: "-80px" },
        { objectPosition: "0 96%", scale: 1, marginTop: "0" },
        3
      );
      tl.fromTo(
        layoutWrapper || null,
        1,
        { paddingTop: "0" },
        { paddingTop: "80px" },
        8
      );
      tl.fromTo(
        homeIntroImageWrapperRef?.current || null,
        1,
        { height: "100vh" },
        { height: "calc(100vh - 80px)" },
        8
      );
      tl.fromTo(
        homeIntroArrowDwnRef?.current || null,
        1,
        { opacity: 0, y: -60 },
        { opacity: 1, y: 0 },
        11
      );
      tl.fromTo(
        headerWrapper || null,
        1,
        { y: -80, opacity: 0 },
        { y: 0, opacity: 1 },
        8
      );
      ScrollTrigger.create({
        trigger: homeIntroImageWrapperRef?.current || "",
        toggleActions: "play none none none",
        start: "bottom+=100% bottom",
        once: true,
        onEnter: () => tl.totalProgress(0.6),
      });
    }
  }, []);

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const gotoAnchor = () => {
    TweenLite.to(window, 0.8, {
      scrollTo: homeIntroImageWrapperRef?.current?.offsetHeight,
    });
  };

  return (
    <HomeIntroImageWrapper ref={homeIntroImageWrapperRef}>
      {firstLoad && (
        <LogoWrapper ref={logoWrapperRef} className="loading">
          <Lottie options={defaultOptions} height={"80%"} width={"100%"} />
        </LogoWrapper>
      )}
      <HomeIntroImageItem src={imageSrc} ref={homeIntroImageRef} alt={"New condos in Newport Beach"} />
      <HomeIntroImageArrow
        src={introArrow}
        onClick={gotoAnchor}
        ref={homeIntroArrowDwnRef}
      />
    </HomeIntroImageWrapper>
  );
};

export default HomeIntroImage;
