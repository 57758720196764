import React, { FC, useEffect, useRef } from "react";
import residence6 from "../../../images/residence-12.png";
import residence7 from "../../../images/residence-13.png";
import residence8 from "../../../images/residence-14.png";
import {
  PamperingContainer,
  PamperingImage,
  PamperingInfo,
  PamperingMainImage,
  PamperingWrapper,
} from "./index.styled";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

const Pampering: FC = () => {
  const pamperingWrapperRef = useRef<HTMLDivElement>(null);
  const pamperingImage1Ref = useRef<HTMLDivElement>(null);
  const pamperingImage2Ref = useRef<HTMLDivElement>(null);
  const pamperingImage3Ref = useRef<HTMLDivElement>(null);
  const pamperingHeaderRef = useRef<HTMLHeadingElement>(null);
  const pamperingTextRef = useRef<HTMLParagraphElement>(null);
  useEffect(() => {
    ScrollTrigger.create({
      trigger: pamperingWrapperRef?.current || "",
      toggleActions: "play none none reverse",
      start: "top+=30% bottom",
      animation: gsap
        .timeline()
        .fromTo(
          pamperingHeaderRef?.current || null,
          0.5,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          ".3"
        )
        .fromTo(
          pamperingTextRef?.current || null,
          0.5,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          ".3"
        )
        .fromTo(
          pamperingImage1Ref?.current || null,
          0.5,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          ".2"
        )
        .fromTo(
          pamperingImage2Ref?.current || null,
          0.5,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          ".2"
        )
        .fromTo(
          pamperingImage3Ref?.current || null,
          0.5,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          "-=.2"
        ),
    });
  }, []);

  const data = useStaticQuery(graphql`
    query {
      residence12: file(relativePath: { eq: "residence-12.png" }) {
        childImageSharp {
          fluid(maxWidth: 2560) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      residence13: file(relativePath: { eq: "residence-13.png" }) {
        childImageSharp {
          fluid(maxWidth: 2560) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      residence14: file(relativePath: { eq: "residence-14.png" }) {
        childImageSharp {
          fluid(maxWidth: 2560) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);
  return (
    <PamperingWrapper ref={pamperingWrapperRef}>
      <PamperingInfo>
        <PamperingContainer>
          <h2 className={"body-title"} ref={pamperingHeaderRef}>
            A PLACE FOR PAMPERING
          </h2>
          <p className={"body-copy"} ref={pamperingTextRef}>
            Gracious, light, and airy, with the finest fixtures and features,
            the bathrooms are defined by large soaking tubs and spa-sized
            showers, all complemented by a bespoke vanity with generous storage.
          </p>
        </PamperingContainer>
        <PamperingImage ref={pamperingImage1Ref}>
          <Img
            fluid={data.residence12.childImageSharp.fluid}
            alt={"Newport beach apartments for sale"}
            loading={"eager"}
          />
        </PamperingImage>
        <PamperingImage ref={pamperingImage2Ref}>
          <Img
            fluid={data.residence13.childImageSharp.fluid}
            alt={"Condominiums Newport Beach"}
            loading={"eager"}
          />
        </PamperingImage>
        <PamperingMainImage ref={pamperingImage3Ref}>
          <Img
            fluid={data.residence14.childImageSharp.fluid}
            alt={"New condos in Newport Beach"}
            loading={"eager"}
          />
        </PamperingMainImage>
      </PamperingInfo>
    </PamperingWrapper>
  );
};

export default Pampering;
