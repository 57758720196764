import React, { FC, useEffect, useRef } from "react";
import residence10 from "../../../images/residence-10.png";
import {
  RestorativeLeft,
  RestorativeRight,
  RestorativeWrapper,
} from "./index.styled";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const Restorative: FC = () => {
  const restorativeWrapperRef = useRef<HTMLDivElement>(null);
  const restorativeRightRef = useRef<HTMLDivElement>(null);
  const restorativeHeaderRef = useRef<HTMLHeadingElement>(null);
  const restorativeTextRef = useRef<HTMLParagraphElement>(null);
  useEffect(() => {
    ScrollTrigger.create({
      trigger: restorativeWrapperRef?.current || "",
      toggleActions: "play none none reverse",
      start: "top+=30% bottom",
      animation: gsap
        .timeline()
        .fromTo(
          restorativeRightRef?.current || null,
          0.5,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          "+=.1"
        )
        .fromTo(
          restorativeHeaderRef?.current || null,
          0.5,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          "-=.2"
        )
        .fromTo(
          restorativeTextRef?.current || null,
          0.5,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          "-=.2"
        ),
    });
  }, []);

  const data = useStaticQuery(graphql`
    query {
      residence10: file(relativePath: { eq: "residence-10.png" }) {
        childImageSharp {
          fluid(maxWidth: 2560) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  return (
    <RestorativeWrapper ref={restorativeWrapperRef}>
      <RestorativeLeft>
        <h2 className={"body-title"} ref={restorativeHeaderRef}>
          A RESTORATIVE ROOM
        </h2>
        <p className={"body-copy"} ref={restorativeTextRef}>
          Light-filled, oversized primary suites are tucked away for privacy
          from the great room and feature windowed five-fixture bathrooms and
          spacious walk-in-closets.
        </p>
      </RestorativeLeft>
      <RestorativeRight ref={restorativeRightRef}>
        <Img
          fluid={data.residence10.childImageSharp.fluid}
          alt={"New condos in Newport Beach"}
          loading={"eager"}
        />
      </RestorativeRight>
    </RestorativeWrapper>
  );
};

export default Restorative;
