import styled from "styled-components";
import { mediaMax } from "../../../helpers/MediaQueries";

export const HomeSliderWrapper = styled.div`
  width: 100%;
  position: relative;
  .slick-dots {
    position: absolute;
    display: flex;
    bottom: 38px;
    left: 50%;
    transform: translateX(-50%);
    list-style: none;

    li {
      padding-left: 0;
      width: 10px;
      height: 10px;
      background: transparent;
      border-radius: 50%;
      display: inline-block;
      border: 1px solid #ffffff;
      margin: 0 5px;
      transition: 0.5s;

      &.slick-active {
        background: white;
      }

      button {
        display: none;
      }
    }
  }

  .slick-list, .slick-slider {
    height: 100%;
  }
`;
export const LeftArrow = styled.div`
  left: 1.875%;
`;

export const RightArrow = styled.div`
  right: 1.875%;
`;

export const HomeSliderItem = styled.div`
  height: calc(100vh - 80px);
  position: relative;
  object-fit: cover;
  object-position: center;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;
export const HomeSliderLabel = styled.p`
  margin: 12px 10% 0;
  width: 80%;
`;
