import React, { FC, useEffect, useRef } from "react";
import { ArrivalLeft, ArrivalRight, ArrivalWrapper } from "./index.styled";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const Arrival: FC = () => {
  const arrivalWrapperRef = useRef<HTMLDivElement>(null);
  const arrivalRightRef = useRef<HTMLDivElement>(null);
  const arrivalLeftRef = useRef<HTMLDivElement>(null);
  const arrivalHeaderRef = useRef<HTMLHeadingElement>(null);
  const arrivalTextImgRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: arrivalWrapperRef?.current || "",
      toggleActions: "play none none reverse",
      start: "top+=30% bottom",
      animation: gsap
        .timeline()
        .fromTo(
          arrivalLeftRef?.current || null,
          0.5,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          "+=.1"
        )
        .fromTo(
          arrivalRightRef?.current || null,
          0.5,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          "+=.1"
        )
        .fromTo(
          arrivalHeaderRef?.current || null,
          0.5,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          "+=.1"
        )
        .fromTo(
          arrivalTextImgRef?.current || null,
          0.5,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          "+=.1"
        ),
    });
  }, []);

  const data = useStaticQuery(graphql`
    query {
      residence1: file(relativePath: { eq: "residence-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 2560) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      residence2: file(relativePath: { eq: "residence-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 2560) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  return (
    <ArrivalWrapper ref={arrivalWrapperRef}>
      <ArrivalLeft ref={arrivalLeftRef}>
        <Img
          fluid={data.residence1.childImageSharp.fluid}
          alt={"Parkhouse arrival"}
          loading={"eager"}
        />
      </ArrivalLeft>

      <ArrivalRight ref={arrivalLeftRef}>
        <Img
          fluid={data.residence2.childImageSharp.fluid}
          alt={"Parkhouse arrival"}
          loading={"eager"}
        />
        <div>
          <h2 className={"body-title"} ref={arrivalHeaderRef}>
            A DISCREET BUT IMPRESSIVE ARRIVAL
          </h2>
          <p className={"body-copy"} ref={arrivalTextImgRef}>
            Striking, canopied entries into bespoke lobbies provide
            direct-elevator admission to your home and access to individual
            garages, allowing for the utmost in privacy. The peaceful lobby sets
            a serene mood while creating a gentle yet impressive transition from
            the world beyond.
          </p>
        </div>
      </ArrivalRight>
    </ArrivalWrapper>
  );
};

export default Arrival;
