import styled from "styled-components";
import { mediaMax } from "../../../helpers/MediaQueries";

export const HomeIntroImageWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 80px);
  position: relative;
  overflow: hidden;
  .intro-animation {
    position: absolute;
  }
`;
export const LogoWrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  z-index: 3;
  background: transparent;
  transition: 0.5s ease-out;
  &.loading {
    background: #3a60aa;
  }
  ${mediaMax.tabletLandscape`
    transition: 10s ease-out;
    div {
      height: 65%!important;
    } 
  `};
  ${mediaMax.phoneXL`
    div {
      height: 40%!important;
    } 
  `};
`;
export const HomeIntroImageItem = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 0 96%;
`;

export const HomeIntroImageArrow = styled.img`
  position: absolute;
  left: 50%;
  bottom: 42px;
  transform: translateX(-50%);
  width: 56px;
  cursor: pointer;
  transition: 0.4s ease-out;
  z-index: 2;
  opacity: 0;
  &:hover {
    opacity: 0.75;
  }
`;
