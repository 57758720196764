import styled from "styled-components";
import { mediaMax } from "../../../helpers/MediaQueries";

export const ArrivalWrapper = styled.div`
  display: flex;
  margin: 0 10% 72px;
  ${mediaMax.tabletLandscape`
    margin: 0 6.25% 72px;
  `}
  ${mediaMax.phoneXL`
    flex-direction: column;
  `}
`;
export const ArrivalLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 49%;
  ${mediaMax.tabletLandscape`
    justify-content: flex-start;
    width: 61.6%;
  `}
  ${mediaMax.phoneXL`
    width: 100%;
  `}
`;
export const ArrivalRight = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  width: 38%;
  .gatsby-image-wrapper {
    margin: 0 0 48px 0;
  }
  ${mediaMax.tabletLandscape`
    width: 31.25%;
    & div {
      padding-top: 100%;
      width: 65vw;
      margin-left: -36.25vw;
    }
  `};
  ${mediaMax.phoneXL`
    width: 100%;
    .gatsby-image-wrapper {
      margin: 48px auto;
      width: 57%;
    }
    & div{
      padding-top: 0;
      width: 100%;
      margin-left: 0;
    }
  `}
`;
