import React, { FC, useEffect, useRef } from "react";
import { ComfortLeft, ComfortRight, ComfortWrapper } from "./index.styled";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const Comfort: FC = () => {
  const comfortWrapperRef = useRef<HTMLDivElement>(null);
  const comfortRightRef = useRef<HTMLDivElement>(null);
  const comfortHeaderRef = useRef<HTMLHeadingElement>(null);
  const comfortTextImgRef = useRef<HTMLParagraphElement>(null);
  useEffect(() => {
    ScrollTrigger.create({
      trigger: comfortWrapperRef?.current || "",
      toggleActions: "play none none reverse",
      start: "top+=30% bottom",
      animation: gsap
        .timeline()
        .fromTo(
          comfortRightRef?.current || null,
          0.5,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          "+=.1"
        )
        .fromTo(
          comfortHeaderRef?.current || null,
          0.5,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          "-=.2"
        )
        .fromTo(
          comfortTextImgRef?.current || null,
          0.5,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          "-=.2"
        ),
    });
  }, []);

  const data = useStaticQuery(graphql`
    query {
      residence4: file(relativePath: { eq: "residence-4.png" }) {
        childImageSharp {
          fluid(maxWidth: 2560) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  return (
    <ComfortWrapper ref={comfortWrapperRef}>
      <ComfortLeft>
        <h2 className={"body-title"} ref={comfortHeaderRef}>
          A NEW HOME WHERE COMFORT IS EVERYTHING
        </h2>
        <p className={"body-copy"} ref={comfortTextImgRef}>
          Bring the best of Newport Beach’s iconic light and ocean breezes into
          your every day through seamless indoor/outdoor living. The great room
          of each condominium home is designed to flow effortlessly onto the terrace,
          separated only by dramatic triple-sliding window walls. Anchored by a
          central fireplace, the homes at Parkhouse&nbsp;Residences are both
          warm and open.
        </p>
      </ComfortLeft>
      <ComfortRight ref={comfortRightRef}>
        <Img
          fluid={data.residence4.childImageSharp.fluid}
          alt={"Condominiums Newport Beach"}
          loading={"eager"}
        />
      </ComfortRight>
    </ComfortWrapper>
  );
};

export default Comfort;
