import styled from "styled-components";
import { mediaMax } from "../../../helpers/MediaQueries";

export const KitchenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 10% 72px;
  ${mediaMax.tabletLandscape`
    margin: 0 6.25% 48px;
  `}
`;
export const KitchenInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
`;
export const KitchenImage = styled.div`
  width: 23%;
  ${mediaMax.tabletLandscape`
    margin-bottom: 50px;
    &:first-child {
      width: 36%;
      order: 3
    }
    &:nth-child(2) {
      width: 31.3%;
      order: 2
    }
  `}
  ${mediaMax.phoneXL`
    margin-bottom: 50px;
    &:first-child {
      width: 47%;
      order: 1
    }
    &:nth-child(2) {
      width: 47%;
      order: 2
    }
  `};
  img {
    width: 100%;
  }
`;
export const KitchenContainer = styled.div`
  width: 38%;
  ${mediaMax.tabletLandscape`
    width: 61.6%;
    order: 1;
  `};
  ${mediaMax.phoneXL`
    width: 100%;
    order: 3;
  `};
`;
export const KitchenMainImage = styled.div`
  width: 59%;
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin: 50px auto 0;
  ${mediaMax.tabletLandscape`
    margin: 0;
    width: 56.8%;
    order: 4;
  `};
  ${mediaMax.phoneXL`
    min-width: 186px;
    margin: 48px auto 0;
  `};
  .gatsby-image-wrapper {
    width: 100%;
  }
`;
