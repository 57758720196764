import React, { FC, useEffect, useRef } from "react";
import { PHMapLeft, PHMapRight, PHMapWrapper } from "./index.styled";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const PHMap: FC = () => {
  const phMapWrapperRef = useRef<HTMLDivElement>(null);
  const phMapRightRef = useRef<HTMLImageElement>(null);
  const phMapLeftRef = useRef<HTMLHeadingElement>(null);

  const data = useStaticQuery(graphql`
    query {
      phMap1: file(relativePath: { eq: "siteMap.png" }) {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      phMap2: file(relativePath: { eq: "sitePlan.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: phMapWrapperRef?.current || "",
      toggleActions: "play none none none",
      start: "top+=10% bottom",
      animation: gsap
        .timeline()
        .fromTo(
          phMapLeftRef?.current || null,
          0.5,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          "+=.1"
        )
        .fromTo(
          phMapRightRef?.current || null,
          0.5,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          "-=.2"
        )
    });
  }, []);

  return (
    <PHMapWrapper ref={phMapWrapperRef}>
      <PHMapLeft ref={phMapLeftRef}>
        <h2 className={"body-title"}>
          UPTOWN NEWPORT
        </h2>
        <p className={"body-copy"}>
          Newport Beach, CA
        </p>
        <Img
          fluid={data.phMap1.childImageSharp.fluid}
          alt={"New condos in Newport Beach"}
          loading={"eager"}
        />
      </PHMapLeft>
      <PHMapRight ref={phMapRightRef}>
        <Img
          fluid={data.phMap2.childImageSharp.fluid}
          alt={"Condominiums Newport Beach"}
          loading={"eager"}
        />
      </PHMapRight>
    </PHMapWrapper>
  );
};

export default PHMap;
