import styled from "styled-components";
import { mediaMax } from "../../../helpers/MediaQueries";

export const RestorativeWrapper = styled.div`
  display: flex;
  margin: 48px 10% 72px;
  ${mediaMax.tabletLandscape`
    margin: 48px 6.25%;
    flex-direction: column-reverse;
    align-items: center;
  `}
  ${mediaMax.phoneXL`
    margin: 48px 6.25%;
    flex-direction: column;
    align-items: center;
  `}
`;
export const RestorativeLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 37%;
  ${mediaMax.tabletLandscape`
    width: 74.4%;
  `}
  ${mediaMax.phoneXL`
    width: 100%;
  `}
`;
export const RestorativeRight = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  width: 48%;
  ${mediaMax.tabletLandscape`
    width: 48.5%;
    margin: 0 0 48px -25%;
  `}
  ${mediaMax.phoneXL`
    width: 57%;
    margin: 48px 0 0 0;
  `}

  img {
    width: 85%;
    ${mediaMax.tabletLandscape`
      width: 100%;  
    `}
  }
`;
